<template>
  <CRow class="justify-content-center m-0 pt-3">
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedLocationList"
        :fields="fields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #FirstEventDate="{ item }">
          <td class="text-center">
            {{ formatDateTimeWithSlash(item?.YardBlDetailJson?.[0]?.FirstEventDate) }}
          </td>
        </template>
        <template #LastEventDate="{ item }">
          <td class="text-center">
            {{ formatDateTimeWithSlash(item?.YardBlDetailJson?.[0]?.LastEventDate) }}
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

//computed
function computedLocationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.form?.Location?.UbicationJson?.map((item, index) => {
    let YardBlDetailJson = item?.YardBlDetailJson?.[0] ?? {};
    return {
      ...item,
      Nro: index+1,
      PackagingName: item[`PackagingName${_lang}`] ?? '',
      YardName: YardBlDetailJson?.YardName ?? 'N/A',
      Quantity: YardBlDetailJson?.Quantity ?? 0,
      DaysInYard: YardBlDetailJson?.DaysInYard ?? 0,
      FirstEventName: YardBlDetailJson?.[`FirstEventName${_lang}`] ?? '',
      LastEventName: YardBlDetailJson?.[`LastEventName${_lang}`] ?? '',
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width: 45px;', filter: false},
    { label: this.$t('label.yard'), key: 'YardName',_style: 'min-width: 200px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.packaging'), key: 'PackagingName',_style: 'min-width: 180px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.quantity'), key: 'Quantity',  _classes: 'text-uppercase text-center', _style: 'min-width: 140px', },
    { label: `${this.$t('label.Day')}S`, key: 'DaysInYard', _classes: 'text-center text-uppercase', _style:'min-width: 140px' },
    { label: this.$t('label.FirstEvent'), key: 'FirstEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.FirstEventDate'), key: 'FirstEventDate',_classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.LastEvent'), key: 'LastEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.LastEventDate'), key: 'LastEventDate',_classes:'text-center', _style:'min-width: 160px' },
  ];
}

export default {
  name: 'location',
  components: {
  
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    formatNumber,
    formatDateTimeWithSlash,
  },
  computed: {
    computedLocationList,
    fields,
  },
  watch: {
  }
};
</script>